<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#1976d2"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-start px-4>
      <v-flex xs12 text-left pt-4>
        <span style="color: #000; font-size: 22px; font-family: poppinsbold"
          >Payment Reports</span
        >
      </v-flex>

      <v-flex xs12 pt-3>
        <v-layout wrap>
          <!-- <v-flex xs12 sm6 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Payments</span
            >
          </v-flex>
          <v-flex xs12 sm6 text-right>
            <span
              style="color: #000; font-size: 18px; font-family: poppinssemibold"
              >Total Amount: <b style="color: green">0 INR</b></span
            >
          </v-flex> -->
          <v-flex v-if="paymentData.length > 0" xs12  text-left>
            <PaymentData
              :paymentData="paymentData"
              :dates="dates"
              :filter="filter"
              :currentPage="currentPage"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex v-else xs12 text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Payments</span
            >
            <br />
            <span class="textField2"> No Payments Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PaymentData from "./paymentReport";
// import Reservation from "./reservation";
export default {
  components: {
    // Reservation,
    PaymentData,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      paymentData: [],
      reservationData: [],
      programme: null,
      slotDetail: null,
      programeData: [],
      details: [],
      slotDetails: [],
      guest: [],
      programs: [],
      slotItems: [],
      panel: [],
      disabled: false,
      dates: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      currentPage: 1,
      pages: 0,

      json_fields: {
        "Transaction ID": "txnid",
        TicketNo: "ticket.ticketNo",
        TransactionDate: "init_date",
        PhoneNumber: "user.phone",
        Amount: "total",
        Status: "verificationStatus",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      date: new Date(),
      totalAmt: 0,
      tabValue: 0,
      titleArray: [
        { title: "BOOKINGS", value: "booking" },
        // { title: "RESERVATIONS", value: "reservation" },
      ],
      type: null,
      filter: null,
      filterStatus: [
        { name: "All", value: null },
        { name: "Verified", value: "Verified" },
        { name: "Not Verified", value: "Not Verified" },
      ],
    };
  },

  beforeMount() {
    if (this.dates) {
      this.dates.setDate(this.dates.getDate());
      this.dates = this.dates.toISOString().substr(0, 10);
    }
    // if (this.toDate) {
    //   this.toDate.setDate(this.toDate.getDate());
    //   this.toDate = this.toDate.toISOString().substr(0, 10);
    // }
  },
  watch: {
    dates() {
      this.getData();
      // this.getExcel();
    },
    filter() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    // toDate() {
    //   this.getData();
    //   this.getExcel();
    //   this.checkDate();
    // },

    // programme() {
    //   this.getData();
    //   this.getExcel();
    // },
  },
  methods: {
    // checkDate() {
    //   let ndate= new Date().toISOString().substr(0, 10);
    //   if (this.toDate == ndate) {
    //     const tomorrow = new Date(this.toDate);
    //     tomorrow.setDate(tomorrow.getDate() + 20);
    //     this.toDate = tomorrow.toISOString().substr(0, 10);
    //   }
    // },
    winStepper(windowData) {
      if (windowData) {
        this.currentPage = windowData.currentPage;
        if (windowData.pages) this.pages = windowData.pages;
        this.type = windowData.type;
        this.getData();
      }
    },
    getData() {
      // console.log("Parent getData",this.currentPage);
      if (this.currentPage) {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/payment/all",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            date: this.dates,
            // to: this.toDate,
            status: this.filter,
            count: 12,
            page: this.currentPage,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.paymentData = response.data.data;
              this.totalAmt = response.data.payamentAmount;
              this.pages = response.data.pages;

              // this.getExcel();
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    // getExcel() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/report/bookingreport",
    //     headers: {
    //        "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       date: this.dates,
    //         // to: this.toDate,
    //         programme: this.programme,
    //         slotDetail: this.slotDetail,
    //         status: this.filter,
    //         // count: 12,
    //         // page: this.currentPage,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         // for (var i = 0; i < response.data.data.length; i++) {
    //         //   response.data.data[i].bookingDate = this.formatDate(
    //         //     response.data.data[i].bookingDate
    //         //   );
    //         //   response.data.data[i].dob = this.formatDate(
    //         //     response.data.data[i].dob
    //         //   );
    //         // }
    //           this.excel = response.data.data;
    //           this.json_data = this.excel;

    //         this.appLoading = false;
    //       } else {
    //         this.msg = response.data.msg;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  },
};
</script>
<style>
.head {
  font-family: poppinsregular;
  font-size: 14px;
}
.content {
  font-family: poppinsbold;
  font-size: 13px;
}
</style>